import React, { useState } from "react"
import { Link } from "gatsby"
import HeaderNav from "./promoHeaderNav"
import logo from "../images/logos/logo1.jpg"
// import logo1 from "../assets/images/icon/logo/14.png"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  NavItem,
  // NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  //     NavbarText
} from "reactstrap"
import * as layoutStyles from "./header.module.scss"

const Header = props => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <header className="header-2 header-6">
      {/* <div className="mobile-fix-option"></div> */}
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="header-contact">
                <ul>
                  <li>Welcome to ThrowThings.com</li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>Call Us:
                    330-297-1752
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <div className="header-dropdown">
                {/* <li className="mobile-wishlist">
                  <Link to="#">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                    View Cart
                  </Link>
                </li> */}
                {/* <li className="mobile-wishlist"> */}
                <form
                  method="post"
                  action="https://s1746.americommerce.com/store/shopcart.aspx"
                >
                  <input type="hidden" name="item" value="s-1746" />
                  <input
                    type="submit"
                    // className="fa fa-shopping-cart"
                    className="btn btn-solid view-cart-top"
                    value="View Cart"
                    name="hidden"
                    alt="View Cart"
                    aria-hidden="true"
                  />
                </form>
                {/* </li> */}

                {/* <li className="mobile-wishlist">
                  <form
                    method="post"
                    action="http://www.cartserver.com/sc/cart.cgi"
                  >
                    <input type="hidden" name="item" value="s-1746" />
                    <input
                      type="image"
                      name="hidden"
                      src=""
                      className="fa fa-shopping-cart"
                      value="View Cart"
                      alt="View Cart"
                      aria-hidden="true"
                    />
                  </form>
                </li> */}
                {/* name="item"
                    border="0"
                    className="fa fa-shopping-cart"
                    aria-hidden="true"
                  /> */}
                {/* <li className="mobile-wishlist">
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>{" "}
                    View AmeriCart
                  </li>
                </form> */}
                {/* <FORM
                  ACTION="http://www.cartserver.com/sc/cart.cgi"
                  METHOD="POST"
                > */}
                {/* <TD> */}
                {/* <INPUT TYPE="hidden" NAME="item" VALUE="s-1746"></INPUT> */}
                {/* <INPUT
                      TYPE="image"
                      BORDER="0"
                      NAME="view"
                      className="fa fa-shopping-cart"
                      aria-hidden="true"
                    ></INPUT> */}
                {/* </TD> */}
                {/* </FORM> */}
                {/* <li className="onhover-dropdown mobile-account">
                  <i className="fa fa-user" aria-hidden="true"></i> My Account
                  <ul className="onhover-show-div">
                    <li>
                      <Link to="#" data-lng="en">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to="#" data-lng="es">
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="main-menu border-section border-top-0">
              <div className="layout2-logo">
                {/* <div className="brand-logo layout2-logo"> */}

                {/* <Link to="#"><img src={"../assets/images/logo/14.png"} className="img-fluid blur-up lazyload" alt="" /></Link> */}
                <Link to="/">
                  <img
                    src={logo}
                    className="img-fluid lazyload"
                    alt="ThrowThings.com logo"
                  />
                </Link>
              </div>
              <div>
                {/* <form className="form_search" role="form">
                                    <input id="query search-autocomplete" type="search" placeholder="Find the best for your pet..." className="nav-search nav-search-field" aria-expanded="true">
                                        <button type="submit" name="nav-submit-button" className="btn-search">
                                            <i className="ti-search"></i>
                                        </button>
                        </form> */}
                <form
                  method="post"
                  action="https://s1746.americommerce.com/store/shopcart.aspx"
                >
                  <input type="hidden" name="item" value="s-1746" />
                  <input
                    type="submit"
                    // className="fa fa-shopping-cart"
                    className="btn btn-solid view-cart-bottom"
                    value="View Cart"
                    name="hidden"
                    alt="View Cart"
                    aria-hidden="true"
                  />
                </form>

                <HeaderNav />
              </div>

              {/* <div className="menu-right pull-right">
                <div className="icon-nav">
                  <ul> */}
              {/* <li className="onhover-div mobile-search">
                      <div>
                        <img
                          src={"../images/icon/search.png"}
                          onclick="openSearch()"
                          className="img-fluid blur-up lazyload"
                          alt=""
                        />
                        <i className="ti-search" onclick="openSearch()"></i>
                      </div>
                      <div id="search-overlay" className="search-overlay">
                        <div>
                          <span
                            className="closebtn"
                            onclick="closeSearch()"
                            title="Close Overlay"
                          >
                            ×
                          </span>
                          <div className="overlay-content">
                            <div className="container">
                              <div className="row">
                                <div className="col-xl-12">
                                  <form>
                                    <div className="form-group">
                                      <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                    </div>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <i className="fa fa-search"></i>
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li> */}
              {/* <li className="onhover-div mobile-setting">
                      <div>
                        <img
                          src={"../assets/images/icon/setting.png"}
                          className="img-fluid blur-up lazyload"
                          alt=""
                        />
                        <i className="ti-settings"></i>
                      </div>
                      <div className="show-div setting">
                        <h6>language</h6>
                        <ul>
                          <li>
                            <Link to="#">english</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">french</Link>{" "}
                          </li>
                        </ul>
                        <h6>currency</h6>
                        <ul className="list-inline">
                          <li>
                            <Link to="#">euro</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">rupees</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">pound</Link>{" "}
                          </li>
                          <li>
                            <Link to="#">doller</Link>{" "}
                          </li>
                        </ul>
                      </div>
                    </li> */}
              {/* <li className="onhover-div mobile-cart">
                      <div>
                        <img
                          src={"../assets/images/icon/cart.png"}
                          className="img-fluid blur-up lazyload"
                          alt=""
                        />
                        <i className="ti-shopping-cart"></i>
                      </div>
                      <ul className="show-div shopping-cart">
                        <li>
                          <div className="media">
                            <Link to="#">
                              <img
                                className="mr-3"
                                src={"../assets/images/fashion/product/1.jpg"}
                                alt="Generic placeholder image"
                              />
                            </Link>
                            <div className="media-body">
                              <Link to="#">
                                <h4>item name</h4>
                              </Link>
                              <h4>
                                <span>1 x $ 299.00</span>
                              </h4>
                            </div>
                          </div>
                          <div className="close-circle">
                            <Link to="#">
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <Link to="#">
                              <img
                                className="mr-3"
                                src={"../assets/images/pro/2.jpg"}
                                alt="Generic placeholder image"
                              />
                            </Link>
                            <div className="media-body">
                              <Link to="#">
                                <h4>item name</h4>
                              </Link>
                              <h4>
                                <span>1 x $ 299.00</span>
                              </h4>
                            </div>
                          </div>
                          <div className="close-circle">
                            <Link to="#">
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <div className="total">
                            <h5>
                              subtotal : <span>$299.00</span>
                            </h5>
                          </div>
                        </li>
                        <li>
                          <div className="buttons">
                            <Link to="cart.html" className="view-cart">
                              view cart
                            </Link>
                            <Link to="#" className="checkout">
                              checkout
                            </Link>
                          </div>
                        </li> 
                      </ul>
                    </li>*/}
              {/* </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row ">
          <div className="col-lg-12">
            <div className="main-nav-center">
              <div>
                <Navbar color="faded" light>
                  <NavbarToggler
                    // id="main-nav"
                    // className="toggle-nav mr-2 sidebar-bar"
                    onClick={toggleNavbar}
                    className="toggle-nav mr-2"
                  />
                  <Collapse isOpen={!collapsed} navbar>
                    <Nav navbar className="sm pixelstrap">
                      {/* <ul id="main-menu" className="sm pixelstrap sm-horizontal"> */}

                      <NavItem>
                        <td width="228" class="background">
                          <div align="center">
                            <strong
                            ><font
                              color="#000000"
                              face="Arial, Helvetica, sans-serif"
                            >
                                Enter Keywords to Search for Promotional
                            Products</font
                              ></strong>
                            <br />

                            <iframe title="Promotional Product Search"
                              src="https://promotional.throwthings.com/Site/aspx?appCode=$1TH&appVersion=1.0.0&page=QuickSearch&fromAppCode=SITA&fromAppVer=2.1.0"
                              // style="width:225; height:75px;"
                              frameborder="0"
                              scrolling="no"
                            ></iframe>
                          </div>
                          <font
                            face="Arial, Helvetica, sans-serif"
                          // style="font-size:11px;"
                          >
                            This search is for custom imprinted promotional products
                            in our “Throw Your Name Around” Department. If you are
                            looking for items in other departments please
                        <strong
                            ><a
                              href="https://www.ThrowThings.com"
                            >
                                click here</a
                              ></strong
                            >.</font
                          >
                        </td>
                      </NavItem>

                      <NavItem>
                        <Link to="/">Home</Link>
                      </NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Popular Promo Products
                        </DropdownToggle>
                        <DropdownMenu right className={layoutStyles.navLink}>
                          <DropdownItem>
                            <a href="https://www.throwthings.com/promotional/">
                              <div>
                                <strong>Throw Your Name Around</strong>
                              </div>
                              <div>Custom Printed</div>
                              <div>Promotional Products</div>
                            </a>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-your-voice">
                              <div>
                                <strong>Throw Your Voice</strong>
                              </div>
                              <div>Ventriloquist Dummies, Puppets</div>
                              <div> & Marionettes</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/tt-things-to-throw">
                              <div>
                                <strong>Things To Throw</strong>
                              </div>
                              <div>Sports, Games, Toys & More</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throwbacks">
                              <div>
                                <strong>Throwbacks</strong>
                              </div>
                              <div>Classic Toys, Games & Gags</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-up">
                              <div>
                                <strong>Throw Up</strong>
                              </div>
                              <div>Fake Vomit & Other Gross Stuff</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-a-party">
                              <div>
                                <strong>Throw A Party</strong>
                              </div>
                              <div>Party Supplies & Costumes</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-your-money-away">
                              <div>
                                <strong>Throw Your Money Away</strong>
                              </div>
                              <div>We Make It Easy</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throwthings-things">
                              <div>
                                <strong>ThrowThings Things</strong>
                              </div>
                              <div>You ThrowThings - Tell The World!</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-it-on-the-barbie">
                              <div>
                                <strong>Throw It On The Barbie</strong>
                              </div>
                              <div>Portable Grills & More</div>
                            </Link>
                          </DropdownItem>
                          <DropdownItem>
                            <Link to="/throw-things-away">
                              <div>
                                <strong>Throw Things Away</strong>
                                <span className="new-tag">new </span>
                              </div>
                              <div>Coming Soon!</div>
                            </Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <NavItem>
                        <Link to="/about">About Us</Link>
                      </NavItem>
                      <NavItem>
                        <Link to="/promotional/artwork">Artwork</Link>
                      </NavItem>
                      {/* <NavItem>
                        <Link to="/shipping">Shipping</Link>
                      </NavItem> */}
                      <NavItem>
                        <Link to="/prmotional/promohelp">Don't See What You Want?</Link>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </div>

              {/* <nav id="main-nav"> */}
              {/* <div className="toggle-nav"> */}
              {/* <i className="fa fa-bars sidebar-bar"></i> */}
              {/* </div> */}
              {/* <!-- Sample menu definition --> */}
              {/* < HeaderNav /> */}
              {/* </nav> */}
            </div>
          </div>
        </div>
      </div>
    </header >
  )
}

export default Header
