import React from "react"
import { Link } from "gatsby"
// import Slider from "react-slick";
// import HomeSlider from "../components/homeSlider"
import LogoBlock from "../../components/logo-block"
// import BlogSection from "../components/blogsection"
// import HomeParallax from "../components/homeParallax"
import Head from "../../components/head"

import Layout from "../../components/promoLayout"
// import homebanner46 from "../assets/images/home-banner/46.jpg"
// import homebanner45 from "../assets/images/home-banner/45.jpg"
// import banner1 from "../assets/images/pets/banner/1.jpg"
import throwyourvoice from "../../images/category/101.jpg"
import thingstothrow from "../../images/category/102.jpg"
import throwbacks from "../../images/category/105.jpg"
import throwup from "../../images/category/106.jpg"
import throwyourmoneyaway from "../../images/category/108.jpg"
import promo from "../../images/category/promo.jpg"
// import banner2 from "../assets/images/pets/banner/2.jpg"
// import banner3 from "../assets/images/pets/banner/3.jpg"
// import banner4 from "../assets/images/pets/banner/4.jpg"
// import banner5 from "../assets/images/pets/banner/5.jpg"
// import banner6 from "../assets/images/pets/banner/6.jpg"

// import HomeProducts from "../components/homeProducts"
// import HomeProductsTop from "../components/homeProductsTop"

// import "../assets/scss/index.scss"

//<!-- Icons -->
// import "../assets/css/fontawesome.css"

//<!--Slick slider css-->
// import "../styles/css/slick.css"
// import "../assets/css/slick-theme.css"

//<!-- Animate icon -->
// import "../assets/css/animate.css"

//<!-- Themify icon -->
// import "../assets/css/themify-icons.css"

//<!-- Bootstrap css -->
// import "../assets/css/bootstrap.css"

//<!-- Theme css -->
// import "../assets/css/color15.css"

// media="screen" id="color"

// {/* <!-- latest jquery--> */ }
// import "../assets/js/jquery-3.3.1.min.js"

// {/* <!-- menu js--> */ }
// import "../assets/js/menu.js"

// {/* <!-- lazyload js--> */ }
// import "../assets/js/lazysizes.min.js"

// {/* <!-- popper js--> */ }
// import "../assets/js/popper.min.js"

// {/* <!-- slick js--> */ }
// import "../assets/js/slick.js"

// {/* <!-- Bootstrap js--> */ }
// import "../assets/js/bootstrap.js"

// {/* <!-- Bootstrap Notification js--> */ }
// import "../assets/js/bootstrap-notify.min.js"

// {/* <!-- Theme js--> */ }
// import "../assets/js/script.js"

//
const imgPad = {
  paddingBottom: ".5rem",
}
const IndexPage = () => {
  return (
    <Layout>
      <Head
        title="ThrowThings.com Home Page"
        description="You won't believe what you can throw! At ThrowThings.com you'll find customized promotional products in the Throw Your Name Around department, ventriloquist dummies, puppets and marionettes under Throw Your Voice, and toys and games in Things To Throw. Other departments include Throwbacks, Throw A Party, ThrowThings Things, and, of course, Throw Up. You can even Throw Your Money Away."
      />
      {/* <h1>Hello.</h1> */}
      {/* <h2>This is place holder text for the new page.</h2> */}
      {/* -- header start --*/}
      {/* <!-- header end --> */}

      {/* <!-- Home slider --> */}
      {/* <section class="p-0 small-slider">
                <div class="slide-1 home-slider">
                    <div>
                        <div class="home ">
                            <img src={homebanner46} alt="" class="bg-img blur-up lazyload" />
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <div class="slider-contain">
                                            <div>
                                                <h4>every pet is different</h4>
                                                <h1>let's shop</h1>
                                                <Link to="#" class="btn btn-solid">shop now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="home">
                            <img src={homebanner45} alt="" class="bg-img blur-up lazyload" />
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <div class="slider-contain">
                                            <div>
                                                <h4>Any thing that pets want</h4>
                                                <h1>save 20%</h1>
                                                <Link to="#" class="btn btn-solid">shop now</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      {/* <!-- Home slider end --> */}

      {/* <HomeSlider /> */}

      {/* <!-- logo section --> */}
      {/* <LogoBlock /> */}
      {/* <!-- logo section end --> */}

      {/* <!-- Banner section --> */}
      <section className="pt-auto banner-6 ratio2_1 section-b-space">
        <div className="container">
          <h2 className="text-center pb-4">You Won't Believe What You Can Throw!</h2>

          <div className="row partition3">
            <div className="col-md-4">
              <a href="https://www.throwthings.com/promotional/">
                <div className="collection-banner p-left text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={promo}
                      className="img-fluid lazyload bg-img"
                      alt=""
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">
                      Throw Your{" "}
                      <span style={{ whiteSpace: "nowrap" }}>Name Around</span>
                    </h2>
                    <h5>
                      Customized promotional products, hand outs and give-aways
                      with your logos, names or messages. Of course we have
                      custom printed baseballs, beach balls, and flying discs,
                      but with over 750,000 promotional products and advertising
                      specialties, chances are we have just what you're after.
                    </h5>{" "}
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-4">
              <Link to="/throw-your-voice">
                <div className="collection-banner p-right text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={throwyourvoice}
                      className="img-fluid lazyload bg-img"
                      alt="Throw Your Voice department"
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">Throw Your Voice</h2>
                    <h5>
                      Like putting on shows? Want to amuse yourself or friends?
                      Entertain anyone with our various assortments of
                      ventriloquist dummies, hand puppets and marionettes.
                      Accessories, replacement parts for your dummy and DVDs and
                      books to learn ventriloquism also available
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/tt-things-to-throw">
                <div className="collection-banner p-left text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={thingstothrow}
                      className="img-fluid lazyload bg-img"
                      alt="Things To Throw department"
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">Things To Throw</h2>
                    <h5>
                      From mini balls to foam bricks to hackey sacks, if you
                      want to throw it, chances are you can find it right here
                      Check out all these great products!
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row partition3 banner-top-cls">
            <div className="col-md-4">
              <Link to="/throwbacks">
                <div className="collection-banner p-right text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={throwbacks}
                      className="img-fluid lazyload bg-img"
                      alt="Throw Backs department"
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">Throw Backs</h2>
                    <h5>
                      Classic Toys, Games & Gags. Go back in time and remember
                      the good old days with these great toys, games and gags!
                      Get cool items ranging from traditional wooden toys to all
                      time favorite novelty jokes and pranks!
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/throw-up">
                <div className="collection-banner p-right text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={throwup}
                      className="img-fluid lazyload bg-img"
                      alt="Throw Up department"
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">Throw Up</h2>
                    <h5>
                      Fake Vomit & Other Gross Stuff! Some great ways to gross
                      anyone out! A little fake vomit to trick your friends or
                      even a barrel full of vomit for even the hard to get
                      laughs!
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-4">
              <Link to="/throw-your-money-away">
                <div className="collection-banner p-right text-center">
                  <div className="img-part" style={imgPad}>
                    <img
                      src={throwyourmoneyaway}
                      className="img-fluid lazyload bg-img"
                      alt="Throw Your Money Away"
                    />
                  </div>
                </div>
                <div className="contain-banner banner-3">
                  <div>
                    <h2 className="text-center">
                      Throw Your{" "}
                      <span style={{ whiteSpace: "nowrap" }}>Money Away</span>
                    </h2>
                    <h5>
                      What more can we say? Throw your money away and you'll get
                      NOTHING! Well...you will get a really cool certificate
                      from us!
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner section End --> */}
      <LogoBlock />

      {/*Product Slider*/}

      {/* Put back in */}

      {/* <div className="container">
        <div className="row">
          <div className="col">
            <div className="title1 title5">
              <h2 className="title-inner1">Some Fun Stuff</h2>
              <hr className="tournament6"></hr>
            </div>
          </div>
        </div>
      </div>

      <section className="section-b-space p-t-0 j-box pets-box ratio_square">
        <HomeProductsTop />
      </section> */}
      {/*Product Slider End*/}

      {/* <!-- Parallax banner --> */}
      {/* Put back in */}
      {/* <HomeParallax /> */}

      {/* <section className="p-0 pet-parallax">
                <div className="full-banner parallax  text-center p-center">
                    <img src={require("../assets/images/parallax/19.jpg")} alt="" class="bg-img blur-up lazyload" />
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="banner-contain">
                                    <h4>choose what you love</h4>
                                    <h3>get upto 70% off</h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
                            a type specimen book. </p>
                                    <Link to="#" className="btn btn-solid black-btn" tabindex="0">shop now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pet-decor">
                        <img src={require("../assets/images/dog.png")} alt="" class="img-fluid blur-up lazyload" />
                    </div>
                </div>
            </section> */}
      {/* <!-- Parallax banner end --> */}

      {/*Product Slider*/}

      {/* Put back in */}
      {/* <div className="container">
        <div className="row">
          <div className="col">
            <div className="title1 title5">
              <h2 className="title-inner1">save and extra</h2>
              <hr className="tournament6"></hr>
            </div>
          </div>
        </div>
      </div>

      <section className="section-b-space p-t-0 j-box pets-box ratio_square">
        <HomeProducts />
      </section> */}

      {/* <Collection type={'pets'} title="SAVE AND EXTRA" /> */}
      {/*Product Slider End*/}

      {/*<!-- product section start --> */}

      {/* <section className="section-b-space j-box pets-box ratio_square">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title1 title5">
                                <h2 className="title-inner1">save and extra</h2>
                                <hr role="tournament6"></hr>
                            </div>
                            <div className="product-4 product-m no-arrow slick-initialized slick-slider">
                                <div className="product-box">
                                    <div className="img-wrapper">
                                        <div className="front">
                                            <Link to="product-page(no-sidebar).html"><img src={require("../assets/images/pets/pro/2.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></Link>
                                        </div>
                                        <div className="cart-info cart-wrap">
                                            <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button>
                                            <Link to="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></Link>
                                            <Link to="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></Link>
                                            <Link to="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></Link>
                                        </div>
                                    </div>
                                    <div className="product-detail">
                                        <div className="rating">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <Link to="product-page(no-sidebar).html">
                                            <h6>Slim Fit Cotton Shirt</h6>
                                        </Link>
                                        <h4>$500.00</h4>
                                    </div>
                                </div>
                                <div className="product-box">
                                    <div className="img-wrapper">
                                        <div className="lable-block">
                                            <span className="lable3">new</span>
                                            <span className="lable4">on sale</span>
                                        </div>
                                        {/* <!-- front image --> */}
      {/* <div className="front">
                <Link to="product-page(no-sidebar).html"><img src={require("../assets/images/pets/pro/8.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></Link>
            </div>
            <div className="cart-info cart-wrap">
                <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button>
                <Link to="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></Link>
                <Link to="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></Link>
                <Link to="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></Link>
            </div>
                                    </div>
        <div className="product-detail">
            <div className="rating">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
            <Link to="product-page(no-sidebar).html">
                <h6>Slim Fit Cotton Shirt</h6>
            </Link>
            <h4>$500.00 <del>$600.00</del></h4>
        </div>
                                </div >
    <div className="product-box">
        <div className="img-wrapper">
            <div className="lable-block">
                <span className="lable3">new</span>
                <span className="lable4">on sale</span>
            </div>
            <div className="front">
                <Link to="product-page(no-sidebar).html"><img src={require("../assets/images/pets/pro/9.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></Link>
            </div>
            <div className="cart-info cart-wrap">
                <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button>
                <Link to="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></Link>
                <Link to="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></Link>
                <Link to="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></Link>
            </div>
        </div>
        <div className="product-detail">
            <div className="rating">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
            <Link to="product-page(no-sidebar).html">
                <h6>Slim Fit Cotton Shirt</h6>
            </Link>
            <h4>$500.00</h4>
        </div>
    </div>
    <div className="product-box">
        <div className="img-wrapper">
            <div className="front">
                <Link to="product-page(no-sidebar).html"><img src={require("../assets/images/pets/pro/14.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></Link>
            </div>
            <div className="cart-info cart-wrap">
                <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button>
                <Link to="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></Link>
                <Link to="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></Link>
                <Link to="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></Link>
            </div>
        </div>
        <div className="product-detail">
            <div className="rating">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
            <Link to="product-page(no-sidebar).html">
                <h6>Slim Fit Cotton Shirt</h6>
            </Link>
            <h4>$500.00</h4>
        </div>
    </div>
    <div className="product-box">
        <div className="img-wrapper">
            <div className="lable-block">
                <span className="lable3">new</span>
                <span className="lable4">on sale</span>
            </div>
            <div className="front">
                <Link to="product-page(no-sidebar).html"><img src={require("../assets/images/pets/pro/10.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" /></Link>
            </div>
            <div className="cart-info cart-wrap">
                <button data-toggle="modal" data-target="#addtocart" title="Add to cart"><i className="ti-shopping-cart" ></i></button>
                <Link to="javascript:void(0)" title="Add to Wishlist"><i className="ti-heart" aria-hidden="true"></i></Link>
                <Link to="#" data-toggle="modal" data-target="#quick-view" title="Quick View"><i className="ti-search" aria-hidden="true"></i></Link>
                <Link to="compare.html" title="Compare"><i className="ti-reload" aria-hidden="true"></i></Link>
            </div>
        </div>
        <div className="product-detail">
            <div className="rating">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
            </div>
            <Link to="product-page(no-sidebar).html">
                <h6>Slim Fit Cotton Shirt</h6>
            </Link>
            <h4>$500.00</h4>
        </div>
    </div>
                            </div >
                        </div >
                    </div >
                </div >
            </section > * /} */}

      {/*<!-- product section end --> */}

      {/* <!-- blog section start --> */}

      {/* <div className="container">
        <div className="row">
          <div className="col">
            <div className="title1 title5">
              <h4>Recent Story</h4>
              <h2 className="title-inner1">from the blog</h2>
              <hr className="tournament6"></hr>
            </div>
          </div>
        </div>
      </div>

      <section className="section-b-space p-t-0 ratio2_3">
        <BlogSection />
      </section> */}

      {/* <section className="section-b-space blog p-t-0 blog_box ratio2_3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slide-3  no-arrow">
                                <div className="col-md-12">
                                    <Link to="#">
                                        <div className="classic-effect">
                                            <div>
                                                <img src={require("../assets/images/pets/blog/1.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <span></span>
                                        </div>
                                    </Link>
                                    <div className="blog-details">
                                        <Link to="#">
                                            <p>Lorem ipsum dolor sit consectetur adipiscing elit,</p>
                                        </Link>
                                        <p>Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit</p>
                                        <Link className="read-cls" to="blog.html">read more</Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Link to="#">
                                        <div className="classic-effect">
                                            <div>
                                                <img src={require("../assets/images/pets/blog/2.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <span></span>
                                        </div>
                                    </Link>
                                    <div className="blog-details">
                                        <Link to="#">
                                            <p>Lorem ipsum dolor sit consectetur adipiscing elit,</p>
                                        </Link>
                                        <p>Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit</p>
                                        <Link className="read-cls" to="blog.html">read more</Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Link to="#">
                                        <div className="classic-effect">
                                            <div>
                                                <img src={require("../assets/images/pets/blog/4.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <span></span>
                                        </div>
                                    </Link>
                                    <div className="blog-details">
                                        <Link to="#">
                                            <p>Lorem ipsum dolor sit consectetur adipiscing elit,</p>
                                        </Link>
                                        <p>Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit</p>
                                        <Link className="read-cls" to="blog.html">read more</Link>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Link to="#">
                                        <div className="classic-effect">
                                            <div>
                                                <img src={require("../assets/images/pets/blog/3.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" />
                                            </div>
                                            <span></span>
                                        </div>
                                    </Link>
                                    <div className="blog-details">
                                        <Link to="#">
                                            <p>Lorem ipsum dolor sit consectetur adipiscing elit,</p>
                                        </Link>
                                        <p>Lorem ipsum dolor sit consectetur adipiscing elit,Lorem ipsum dolor sit consectetur adipiscing elit</p>
                                        <Link className="read-cls" to="blog.html">read more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 */}

      {/* <!-- blog section end --> */}

      {/* <!-- latest jquery--> */}
      {/* <script src="../assets/js/jquery-3.3.1.min.js"></script> */}

      {/* <!-- menu js--> */}
      {/* <script src="../assets/js/menu.js"></script> */}

      {/* <!-- lazyload js--> */}
      {/* <script src="../assets/js/lazysizes.min.js"></script> */}

      {/* <!-- popper js--> */}
      {/* <script src="../assets/js/popper.min.js"></script> */}

      {/* <!-- slick js--> */}
      {/* <script src="../assets/js/slick.js"></script> */}

      {/* <!-- Bootstrap js--> */}
      {/* <script src="../assets/js/bootstrap.js"></script> */}

      {/* <!-- Bootstrap Notification js--> */}
      {/* <script src="../assets/js/bootstrap-notify.min.js"></script> */}

      {/* <!-- Theme js--> */}
      {/* <script src="../assets/js/script.js"></script> */}

      {/* <script>
        $(window).on('load', function() {
            $('#exampleModal').modal('show');
    });

    function openSearch() {
            document.getElementById("search-overlay").style.display = "block";
    }

    function closeSearch() {
            document.getElementById("search-overlay").style.display = "none";
    }
</script> */}
    </Layout>
  )
}

export default IndexPage
