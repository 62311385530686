import React from "react"
import Footer from "./footer"
import Header from "./promoHeader"
import * as layoutStyles from "./layout.module.scss"
// import { Container } from "reactstrap"

import "../assets/scss/index.scss"

//<!-- Icons -->
import "../assets/css/fontawesome.css"

//<!--Slick slider css-->
import "../styles/css/slick.css"
import "../assets/css/slick-theme.css"

//<!-- Animate icon -->
import "../assets/css/animate.css"

//<!-- Themify icon -->
import "../assets/css/themify-icons.css"

//<!-- Bootstrap css -->
import "../assets/css/bootstrap.css"

//<!-- Theme css -->
import "../assets/css/color15.css"

// import Head from "./head"


const Layout = props => {
  return (
    <div className={layoutStyles.containertwo}>
      <div className={layoutStyles.content}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
